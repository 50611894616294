import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import YouTube from 'react-youtube';
export const _frontmatter = {
  "path": "/blog/local-development-nirvana",
  "date": "24th April 2024",
  "title": "Patterns for Local Development Nirvana",
  "summary": "Come with us on a journey to local development environment nirvana. Along the way, you'll gather a list of actionable learnings to improve the dev experience.",
  "author": "Nick Schuch",
  "tag": "Case Study",
  "tagColor": "green",
  "tags": [{
    "name": "presentation"
  }, {
    "name": "local development"
  }, {
    "name": "case study"
  }]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Our Journey`}</h2>
    <p>{`At PreviousNext (the team that built Skpr), we've been on a decade-long journey towards local development environment nirvana.`}</p>
    <p>{`From artisanally-crafted snowflake workstation configurations to consistent prepackaged bundles, it's safe to say we've accumulated a wealth of learnings over the years.`}</p>
    <p>{`I recently spoke at the annual DrupalSouth conference, describing this journey and what we discovered along the way.`}</p>
    <h2>{`Learnings`}</h2>
    <p>{`In this talk, I cover the following key points:`}</p>
    <ul>
      <li parentName="ul">{`The time to 200 metric`}</li>
      <li parentName="ul">{`Container image composition`}</li>
      <li parentName="ul">{`Localhost networking as the default`}</li>
      <li parentName="ul">{`Configuration (settings.php)`}</li>
      <li parentName="ul">{`Database images`}</li>
      <li parentName="ul">{`Performance testing`}</li>
    </ul>
    <p>{`For more information on these topics, please read `}<a parentName="p" {...{
        "href": "https://www.previousnext.com.au/blog/patterns-local-development-nirvana"
      }}>{`my PreviousNext blog post`}</a>{` and watch my talk on YouTube below.`}</p>
    <h2>{`Watch the video`}</h2>

    <YouTube videoId="87sLZnk5u-Y" opts={{
      height: '420',
      width: '100%'
    }} mdxType="YouTube" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      